import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	isAuthorized: false,
	isAuthenticated: false,
	loading: false,
	hasErrors: false,
	profile: {},
	data: [],
	searchQuery: "",
	interests: [],
	message: "",
	users: [],
	faqs: [],
	content: [],
	userProfile: {},
	events: [],
	featuredEvents:[],
	upcomingEvents:[],
	singleEvent: {},
	singleInterest: {},
	organizers: [],
	offers: [],
	offer: {},
	tickets: [],
	ticket: {},
	ticketScanner: {},
	scannerTickets: [],
	bookings:[],
	revenue:[],
	returningCustomer:{},
	gender:{},
	booking:{},
	notifications:[],
	faq: {},
	singleContent: {},
	coupons:[],
	coupon:{},
	singleNotification:{}
}

const appReducer = createSlice({
	name: "app",
	initialState,
	reducers: {
		requestStart: (state) => {
			state.loading = true
		},
		requestCompleted: (state) => {
			state.loading = false
		},

		requestSuccess(state, { payload }) {
			state.data = payload
			state.loading = false
			state.hasErrors = false
		},

		requestFailure(state) {
			state.loading = false
			state.hasErrors = true
		},

		setSearchQuery(state, { payload }) {
			state.searchQuery = payload
		},
		resetData(state) {
			state.data = []
		},
		setAuthenticated(state, { payload }) {
			state.isAuthenticated = payload
		},
		setInterstes(state, { payload }) {
			state.interests = payload
		},
		changeAInterstes(state, { payload }) {
			const tempInterest = state.interests.map((item) => {
				if (item?._id === payload?.id) {
					item.isActive = !item.isActive
				}
				return item
			})
			state.interests = tempInterest
		},
		changeUser(state, { payload }) {
			const tempInterest = state.users.map((item) => {
				if (item?._id === payload?.id) {
					item.active = !item.active
				}
				return item
			})
			state.users = tempInterest
		},
		removeInterest(state, { payload }) {
			const tempInterest = state.interests.filter((item) => {
				return item?._id !== payload?.id
			})
			state.interests = tempInterest
		},
		updateInterest(state, { payload }) {
			const tempInterest = state.users.map((item) => {
				if (item?._id === payload?.id) {
					item.name = !item.name
				}
				return item
			})
			state.users = tempInterest
		},
		setProfile(state, { payload }) {
			state.profile = payload
		},
		setUserProfile(state, { payload }) {
			state.userProfile = payload
		},
		setMessage(state, { payload }) {
			state.message = payload
		},
		setUsers(state, { payload }) {
			state.users = payload
		},
		setFAQs(state, { payload }) {
			state.faqs = payload
		},
		addNewFAQ(state, { payload }) {
			state.faqs = [...state.faqs, payload]
		},
		removeFAQ(state, { payload }) {
			state.faqs = state.faqs.filter((item) => item._id !== payload)
		},
		updateFAQStatus(state, { payload }) {
			const temp = state.faqs.map((item) => {
				if (item?._id === payload?.id) {
					item.status = !item.status
				}
				return item
			})
			state.faqs = temp
		},
		setContent(state, { payload }) {
			state.content = payload
		},
		addNewContent(state, { payload }) {
			state.content = [...state.content, payload]
		},
		removeContent(state, { payload }) {
			state.content = state.content.filter((item) => item._id !== payload)
		},

		updateContentStatus(state, { payload }) {
			const temp = state.content.map((item) => {
				if (item?._id === payload?.id) {
					item.status = !item.status
				}
				return item
			})
			state.content = temp
		},
		updateStatus(state, { payload }) {
			const temp = state.users.map((item) => {
				if (item?._id === payload?.id) {
					item.active = !item.active
				}
				return item
			})
			state.users = temp
		},
		updateActiveEvent(state, { payload }) {
			const temp = state.events.map((item) => {
				if (item?._id === payload?.id) {
					item.active = !item.active
				}
				return item
			})
			state.users = temp
		},
		updateActiveCoupon(state, { payload }) {
			const temp = state.coupons.map((item) => {
				if (item?._id === payload?.id) {
					item.status = !item.status
				}
				return item
			})
			state.coupons = temp
		},
		removeUser(state, { payload }) {
			const tempUsers = state.users.filter((item) => {
				return item?._id !== payload?.id
			})
			state.users = tempUsers
		},
		setEvents(state, { payload }) {
			state.events = payload
		},
		setUpcomingEvents(state, { payload }) {
			state.upcomingEvents = payload
		},
		setNotifications(state, { payload }) {
			state.notifications = payload
		},
		setSingleInterest(state, { payload }) {
			state.singleInterest = payload
		},
		setSingleEvent(state, { payload }) {
			state.singleEvent = payload
		},
		setOrganizers(state, { payload }) {
			state.organizers = payload
		},
		removeEvent(state, { payload }) {
			const tempEvent = state.events.filter((item) => {
				return item?._id !== payload?._id
			})
			state.events = tempEvent
		},
		setDeleteNotifications(state, { payload }) {
			const tempEvent = state.notifications.filter((item) => {
				return item?.id !== payload?.id
			})
			state.notifications = tempEvent
		},
		setOffers(state, { payload }) {
			state.offers = payload
		},
		removeOffer(state, { payload }) {
			const tempOffers = state.offers.filter((item) => {
				return item?._id !== payload?.id
			})
			state.offers = tempOffers
		},
		setOffer(state, { payload }) {
			state.offer = payload
		},
		updateActiveOffer(state, { payload }) {
			const temp = state.offers.map((item) => {
				if (item?._id === payload?.id) {
					item.status = !item.status
				}
				return item
			})
			state.offers = temp
		},
		setTickets(state, { payload }) {
			state.tickets = payload
		},
		setTicket(state, { payload }) {
			state.ticket = payload
		},
		setSingleNotification(state, { payload }) {
			state.singleNotification = payload
		},
		setTicketScanner(state, { payload }) {
			state.scannerTickets = payload
		},
		setBookings(state, { payload }) {
			state.bookings = payload
		},
		setRevenue(state, { payload }) {
			state.revenue = payload
		},
		setReturningCustomer(state, { payload }) {
			state.returningCustomer = payload
		},
		setGender(state, { payload }) {
			state.gender = payload
		},
		setCoupons(state, { payload }) {
			state.coupons = payload
		},
		setSingleCoupon(state, { payload }) {
			state.coupon = payload
		},
		setSingleTicketScanner(state, { payload }) {
			state.ticketScanner = payload
		},
		setSingleBooking(state, { payload }) {
			state.booking = payload
		},
		setActiveTicketScanner(state, { payload }) {
			const tempInterest = state.scannerTickets.map((item) => {
				if (item?._id === payload?.id) {
					item.status = !item.status
				}
				return item
			})
			state.scannerTickets = tempInterest
		},
		removeTicket(state, { payload }) {
			const tempTickets = state.tickets.filter((item) => {
				return item?._id !== payload?.id
			})
			state.tickets = tempTickets
		},
		removeTicketScanner(state, { payload }) {
			const tempTickets = state.scannerTickets.filter((item) => {
				return item?._id !== payload?.id
			})
			state.scannerTickets = tempTickets
		},
		setFaq(state, { payload }) {
			state.faq = payload
		},
		setSingleContent(state, { payload }) {
			state.singleContent = payload
		},
		setFeaturedEvents(state, { payload }) {
			state.featuredEvents = payload
		},
	},
})

export const data = (state) => state.app.data
export const loading = (state) => state.app.loading
export const hasError = (state) => state.app.hasErrors
export const searchQuery = (state) => state.app.searchQuery
export const isAuthenticated = (state) => state.app.isAuthenticated
export const interests = (state) => state.app.interests
export const profileData = (state) => state.app.profile
export const message = (state) => state.app.message
export const users = (state) => state.app.users
export const faqs = (state) => state.app.faqs
export const content = (state) => state.app.content
export const userProfile = (state) => state.app.userProfile
export const events = (state) => state.app.events
export const singleInterest = (state) => state.app.singleInterest
export const singleEvent = (state) => state.app.singleEvent
export const organizer = (state) => state.app.organizers
export const offers = (state) => state.app.offers
export const offer = (state) => state.app.offer
export const tickets = (state) => state.app.tickets
export const ticket = (state) => state.app.ticket
export const ticketScanner = (state) => state.app.ticketScanner
export const scannerTickets = (state) => state.app.scannerTickets
export const bookings = (state) => state.app.bookings
export const revenue = (state) => state.app.revenue
export const booking = (state) => state.app.booking
export const notifications = (state) => state.app.notifications
export const faq = (state) => state.app.faq
export const singleContent = (state) => state.app.singleContent
export const singleNotification = (state) => state.app.singleNotification
export const featured_events =(state)=> state.app.featuredEvents
export const upcomingEvents =(state)=> state.app.upcomingEvents
export const coupons =(state)=> state.app.coupons
export const coupon =(state)=> state.app.coupon
export const returningCustomer =(state)=> state.app.returningCustomer
export const gender =(state)=> state.app.gender

export const {
	requestStart,
	requestSuccess,
	requestFailure,
	requestCompleted,
	setSearchQuery,
	resetData,
	setAuthenticated,
	setInterstes,
	changeAInterstes,
	removeInterest,
	setProfile,
	setMessage,
	setUsers,
	setFAQs,
	addNewFAQ,
	removeFAQ,
	updateFAQStatus,
	setContent,
	addNewContent,
	removeContent,
	updateContentStatus,
	removeUser,
	updateStatus,
	setUserProfile,
	changeUser,
	setEvents,
	setUpcomingEvents,
	setSingleEvent,
	setSingleNotification,
	setSingleInterest,
	setSingleCoupon,
	updateActiveEvent,
	updateActiveCoupon,
	setOrganizers,
	removeEvent,
	setOffers,
	removeOffer,
	setOffer,
	updateActiveOffer,
	setTickets,
	setTicket,
	setTicketScanner,
	setBookings,
	setRevenue,
	setNotifications,
	setSingleTicketScanner,
	setCoupons,
	setSingleBooking,
	setActiveTicketScanner,
	setDeleteNotifications,
	removeTicket,
	removeTicketScanner,
	setFaq,
	setSingleContent,
	setReturningCustomer,
	setGender,
	updateInterest,
	setFeaturedEvents,
} = appReducer.actions

export default appReducer.reducer
