import Axios from 'axios';


export const axiosIntercepter = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosIntercepter.interceptors.request.use(
  function (config) {

        const token = localStorage.getItem('token')

        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosIntercepter.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.clearToken();
            window.location.assign(window.location.origin);
        }

        const message = error.response?.data?.message || error.message;

        return Promise.reject(error);
    }
);
