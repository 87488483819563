import moment from "moment"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useSelector } from "react-redux"
import { revenue } from "redux/app"
import { axiosIntercepter } from "redux/axios"

function ApexChart() {
	const revenueData = useSelector(revenue)
	const [loading, setLoading] = useState(true)

	const getRequests = () => {
		setLoading(true)
		axiosIntercepter.get("/user/total-adminRevenue?value=month").then(({data: revenueData}) => {
			const monthNames =
				revenueData?.ticketSold?.map((i) => i.month_name?.substring(0, 3)) ?? []
			const revenvueItems =
				revenueData?.ticketSold?.map((i) =>
					isNaN(eval(i.revenue)) ? 0 : eval(i.revenue)
				) ?? []

			const copyOptions = { ...options }
			options.series[0].data = revenvueItems
			options.options.xaxis.categories = monthNames
			setOptions(copyOptions)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		})
	}
	useEffect(() => {
		getRequests()
	}, [])

	const [options, setOptions] = useState({
		series: [
			{
				name: "Revenue",
				data: [76, 85, 101, 98, 87, 105],

				// [76, 85, 101, 98, 87, 105],
			},
		],
		options: {
			chart: {
				type: "bar",
				height: 150,
				width: "100%",
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "25%",
					endingShape: "rounded",
				},
			},
			dataLabels: {
				enabled: false,
			},

			stroke: {
				show: true,
				width: 3,
				colors: ["transparent"],
			},
			xaxis: {
				categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
			},
			yaxis: {
				title: {
					text: "$ (Revenue)",
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " Revenue"
					},
				},
			},
		},
	})

	// useEffect(() => {
	// 	if (revenueData) {

	// 	}
	// }, [revenueData])

	return (
		<div id="chart">
			{!loading && (
				<ReactApexChart
					options={options.options}
					series={options.series}
					type="bar"
					width="470"
					height={260}
				/>
			)}
		</div>
	)
}
export default ApexChart
